<template>
    <div class="no_data_video">
        <div class="title">How to install plugin code?</div>
        <div class="desc">This plugin is <span class="storng_text">FREE</span> to use. Here is the method that makes the
            Al plugin running in these
            three journals. it is a very easy way to do.</div>
        <div class="box_content">
            <div class="content">
                <div class="content_title">Getting started</div>
                <div class="content_steps">
                    <el-steps direction="vertical" :active="active">
                        <el-step v-for="(item, index) in noDataSteps" :key="index" :title="item.title"
                            :description="item.description" @click.native="onStepClick(index)"></el-step>
                    </el-steps>
                </div>
            </div>
            <div class="content_video">
                <!-- <video :src="noDataSteps[active].video" controls></video> -->
            </div>
        </div>
    </div>
</template>

<script>
import { noDataSteps } from '../data';

export default {
    data() {
        return {
            active: 0,
            noDataSteps
        }
    },
    methods: {
        onStepClick(index) {
            this.active = index
        }
    }
}
</script>

<style lang="scss">
.no_data_video {
    width: 1000px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 30px;
        font-weight: bold;
    }

    .desc {
        font-size: 16px;
        width: 666px;
        line-height: 1.5;
        margin: 30px 0;
        margin-bottom: 50px;

        .storng_text {
            color: #b90116;
            font-weight: bold;
            font-size: 20px;
        }
    }

    .box_content {
        position: relative;
        width: 100%;
        height: 100%;

        .content {
            position: absolute;
            bottom: -20px;
            left: -100px;
            width: 1000px;
            padding: 40px;
            box-sizing: border-box;
            background-color: #e1f8fa;
            height: 400px;

            .content_title {
                font-size: 30px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .content_steps {
                max-width: 420px;
                height: 210px;

                .el-steps {
                    .el-step {
                        &:hover {
                            cursor: pointer;
                        }

                        .el-step__title.is-process {
                            font-weight: 100
                        }

                        .el-step__title.is-wait,
                        .el-step__head.is-wait,
                        .el-step__description.is-wait {
                            color: currentcolor;
                        }

                        .el-step__icon.is-text {
                            border-color: unset;
                        }
                    }
                }
            }
        }

        .content_video {
            width: 698px;
            height: 431px;
            position: absolute;
            z-index: 9;
            background-color: white;
            right: -100px;
            box-shadow: 0px 2px 9px 4px #00000029;

            video {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    .no_data_video {
        width: 100%;
        height: auto;
        padding: 0 20px;

        .title {
            font-size: 24px;
        }

        .desc {
            width: 100%;
            margin-bottom: 30px;
        }

        .box_content {
            display: flex;
            flex-direction: column;

            .content {
                position: static;
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                padding: 20px;

                .content_title {
                    font-size: 24px;
                }

                .content_steps {
                    max-width: max-content;
                    height: auto;
                }
            }

            .content_video {
                display: none;
                width: 100%;
                position: static;
            }
        }
    }
}
</style>