<template>
    <div class="page_wei">
        <WidgetLayout>
            <template #content>
                <div class="wei_no_data">
                    <div class="wei_content">
                        <div class="no_title">SEARCH RESULTS:</div>
                        <div class="no_desc">No origins matching the search criteria were found.</div>
                    </div>
                </div>

                <div class="wei_form">
                    <NoDataFormVue></NoDataFormVue>
                </div>

                <div class="wei_video">
                    <NoDataVideoVue></NoDataVideoVue>
                </div>

                <div class="wei_evaluate">
                    <EvaluateIssue></EvaluateIssue>
                </div>
            </template>
        </WidgetLayout>
    </div>
</template>

<script>
import WidgetLayout from "./layout.vue"
import EvaluateIssue from "@/components/evaluateIssue/index.vue"
import NoDataFormVue from "./components/noDataForm.vue"
import NoDataVideoVue from "./components/noDataVideo.vue"

export default {
    name: "none",
    components: {
        WidgetLayout,
        EvaluateIssue,
        NoDataFormVue,
        NoDataVideoVue
    },
    data() {
        return {}
    }
}
</script>

<style lang="scss">
@import "../../assets/css/minix.scss";

.page_wei {

    .wei_evaluate,
    .wei_form,
    .wei_video {
        margin-top: 50px;
    }

    .wei_no_data {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        .wei_content {
            @include responsive-width(1200px);

            .no_title {
                font-family: 'Bebas Neue';
                font-size: 30px;
                font-weight: bold;
                border-left: 4px solid #1ba4b7;
                padding-left: 10px;
            }

            .no_desc {
                font-size: 20px;
                font-style: italic;
                color: #931616;
                text-align: center;
                margin-top: 50px;
                font-weight: bold;
            }
        }
    }

    .wei_video {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .wei_no_data {
        .wei_content {
            .no_title {
                font-size: 24px !important;
            }
        }
    }

    .wei_form {
        .box_2 {
            padding: 20px;
            box-sizing: border-box;

            .box_21 {
                width: 100%;
                padding: 20px 40px 40px 40px;

                &>div:nth-child(1) {
                    font-size: 24px;
                }
            }
        }
    }
}
</style>